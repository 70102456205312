import React, { Component } from "react";
import { Helmet } from "react-helmet";

function MarketingEnterpriseUC() {
    return (
        <Helmet>
            <meta property="og:url" content="https://divivgroup.lpages.co/marketing-for-enterprises-uc/" />
            <link rel="opengraph" href="https://divivgroup.lpages.co/marketing-for-enterprises-uc/" />
            <script src="//divivgroup.lpages.co/_/js/marketing-for-enterprises-uc/"></script>
            <style>{'html{font-family:"Roboto", sans-serif} strong{ color: inherit; } section{ padding: 10px} a:hover{text-decoration:none} #___gatsby, #gatsby-focus-wrapper{display:none}'}</style>
        </Helmet>
    )
}

export default MarketingEnterpriseUC;